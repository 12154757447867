import { createTheme } from 'react-data-table-component'

//Template config options
const themeConfig = {
  app: {
    appName: '',
    appLogoImage: require('@src/assets/images/logo/logo.png').default
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'horizontal', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: true,
    scrollTop: true // Enable scroll to top button
  }
}

export const darkThemeDatatable = createTheme('dark', {
  text: {
    primary: '#b4b7bd',
    secondary: '#b4b7bd'
  },
  background: {
    default: 'transparent'
  },
  context: {
    background: '#6d9dc5',
    text: '#FFFFFF'
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(109,157,197,.08)',
    disabled: 'rgba(0,0,0,.12)'
  }
})

export default themeConfig
